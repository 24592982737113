<template>
  <div class="home">
    <div class="user">
      <div class="info" v-if="Object.keys(data).length != 0">
        <span>姓名：{{ data.name }}</span>
        <span>学号：{{ data.studentId }}</span>
      </div>
      <div class="info" v-else>
        当前未登录，请先登陆
      </div>
      <div style="text-align: right;">
        <button @click="update"><svg t="1663388409724" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3131" width="200" height="200"><path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-0.7-8.9-4.9-10.3l-56.7-19.5c-4.1-1.4-8.6 0.7-10.1 4.8-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4-31.6 31.6-68.4 56.4-109.3 73.8-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27-40.9-17.3-77.7-42.1-109.3-73.8-31.6-31.6-56.4-68.4-73.7-109.4-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27 40.9 17.3 77.7 42.1 109.3 73.8 9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47c-5.3 4.1-3.5 12.5 3 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l0.8-180.9c-0.1-6.6-7.8-10.3-13-6.2z" p-id="3132" data-spm-anchor-id="a313x.7781069.0.i0" class="selected" fill="#ffffff"></path></svg>
          {{ Object.keys(data).length != 0 ? '更新' : '登陆' }}
        </button>
      </div>
    </div>
    <div class="achievement" v-if="Object.keys(course).length != 0">
      <div class="achievement-card" v-for="(item, key,index) in course" :key="index">
        <h3 class="achievement-semester">学期：{{ key }}</h3>
        <div>
          <div class="achievement-title">
            <span class="kc">课程</span>
            <span class="lx">类型</span>
            <span class="jd">学分</span>
            <span class="cj">成绩</span>
          </div>
          <div :class="{ 'is-gk': achievementFail(item2.cj) }" class="achievement-row" v-for="item2,index2 in item" :key="index2">
            <span class="kc" :class="{ 'is-gk': achievementFail(item2.cj) }">{{ item2.kcmc }}</span>
            <span class="lx">{{ item2.kcxzmc }}</span>
            <span class="jd">{{ item2.xf }}</span>
            <span class="cj">{{ item2.cj }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      course: {},
      data: {}
    }
  },
  methods: {
    update() {
      this.$router.push('/login')
    },
    achievementFail(achievement) {
      return achievement < 60 ? true : false
    }
  },
  mounted(){
    let data = localStorage.getItem('data')
    if (!data) {
      return
      // this.$router.push('/login')
    }
    data = JSON.parse(data)
    let dataList = data['course']
    const tempCourse = {}
    // xqmmc 上下学期
    // xnmmc 学期
    dataList.forEach((value, index, array) => {
      const cousexn = `${value['xnmmc']}-${value['xqmmc']}`  
      if (!tempCourse[cousexn]) {
        tempCourse[cousexn] = []
        tempCourse[cousexn].push(value)
      } else {
        tempCourse[cousexn].push(value)
      }
    })

    console.log('aaa', tempCourse)

    this.course = tempCourse
    this.data.name = data.name
    this.data.studentId = data.studentId
    
  }
}
</script>

<style scoped>
.home {
  min-height: 100vh;
  padding: 1.2rem;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.user {
  border-radius: 10px;
  padding: 12px 25px;
  font-weight: 600;
  margin-bottom: 20px;
  /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); */
  background: url('@/assets/img/user-bg.png');
  background-size: cover;
}.user .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}.user button {
  outline: none;
  border: none;
  border-radius: 15px;
  padding: 4px 20px;
  font-size: 12px;
  background-image: linear-gradient(to top, #ebbba7 0%, #cfc7f8 100%);
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}.user button svg {
  height: 12px;
  width: 12px;
  margin-right: 3px;
}

/* 学期标题 */
.achievement-semester {
  font-size: 18px;
  color: #505758;
  margin-bottom: 10px;
}

/* 成绩 */
.achievement {
  display: flex;
  flex-direction: column-reverse;
}.achievement-card {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  box-shadow: 10 10px 10px rgba(0, 0, 0, 0.12);
}

.achievement-title,
.achievement-row {
  display: flex;
  justify-content: space-around;
  text-align: left; 
}
.achievement-title {
  font-weight: 600;
  font-size: 17px;
  color: #7f8c8d;
  padding-bottom: 5px;
}
.achievement-row {
  padding: 6px 0;
  font-size: 15px;
  color: #34495e;
  border-bottom: 1px dotted #d0d5d8;
}

.kc,.jd,.cj,.lx {
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.kc {
  flex: 1;
  color: #7f8c8d;
}

.jd {
  width: 15%;
  text-align: center;
}

.cj {
  width: 15%;
  text-align: center;
}

.lx {
  width: 25%;
  text-align: center;
}

/* 挂科 */
.is-gk {
  color: #e74c3c !important;
}

</style>
